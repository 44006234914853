import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HeaderComponent } from '../../components/header/header.component';
import { SharedModule } from '../../modules/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, HeaderComponent],
  selector: 'playground-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(private authService: MsalService) {}

  logout() {
    this.authService.logoutRedirect();
  }
}
