<mat-drawer-container autosize>
  <div class="playground">
    <div class="playground-container">
      <div class="header">
        <playground-header [isPlayground]="true" (newDialogChange)="onNewDialogChange()"></playground-header>
      </div>
      <div class="playground-content">
        <ul class="message-list" #messageList *ngIf="messageList$ | async as messageList">
          <ng-container *ngFor="let item of messageList; let messageIndex = index">
            <li *ngIf="!item.isHidden" class="message-item">
              <div *ngIf="item.role === role.User" class="message-content user">
                <p class="message user" [innerHTML]="formatUserAnswer(item.content)"></p>
                <span class="icon-user-avatar">ID</span>
              </div>
              <div *ngIf="item.role === role.Assistant" class="message-content">
                <span class="icon-assistant-avatar"></span>
                <div class="message assistant">
                  <p [innerHTML]="formatAnswer(item.content)"></p>
                  <div class="actions" *ngIf="item.isCopy">
                    <div class="action-button" (click)="regenerate()" *ngIf="messageIndex >= messageList.length - 2">
                      <mat-icon svgIcon="refresh" class="icon-copy"></mat-icon>
                      Regenerate
                    </div>
                    <div class="action-button" (click)="copy(item.content)">
                      <mat-icon svgIcon="copy" class="icon-copy"></mat-icon>
                      Copy
                    </div>
                  </div>
                </div>
              </div>
              <div class="quick-action" *ngIf="item.quickActions && item.quickActions.length > 0">
                <ul class="quick-action-items">
                  <p class="quick-action-title">Suggested prompts</p>
                  <li
                    class="quick-action-item"
                    *ngFor="let quickAction of item.quickActions; let index = index"
                    [ngClass]="{
                      selected: index === item.selectedIndex,
                      disabled: isLoading,
                    }"
                    (click)="sendQuickAction(messageIndex, index, quickAction, item.parentQuickAction)"
                  >
                    {{ quickAction }}
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>

          <div class="loading-box" *ngIf="isLoading">
            <span class="icon-assistant-avatar"></span>
            <ul class="loading">
              <li class="loading-item"></li>
              <li class="loading-item"></li>
              <li class="loading-item"></li>
            </ul>
          </div>
        </ul>
        <div class="footer">
          <div class="input-box">
            <textarea
              #autoTextarea
              id="autoTextarea"
              class="auto-textarea"
              [placeholder]="messagePlaceholder"
              [(ngModel)]="message"
              (keydown)="onAutoTextareaKeyup($event)"
              [rows]="1"
              [disabled]="isLoading"
            ></textarea>

            <span
              class="icon-send"
              [ngClass]="{ disabled: message.length === 0 || isLoading }"
              (click)="getAnswer()"
            ></span>
          </div>
          <div class="tips"></div>
        </div>
      </div>
    </div>
    <div class="toggle-button" *ngIf="!drawer.opened">
      <div class="icon-toggle">
        <span class="icon-toggle-tip" (click)="toggleDrawer(drawer)"></span>
      </div>
      <ng-container *ngTemplateOutlet="knowledgeBaseTips"></ng-container>
    </div>
  </div>
  <mat-drawer #drawer mode="side" position="end" style="overflow: hidden">
    <div class="knowledge-base">
      <div class="toggle-button" (click)="drawer.close()">
        <span class="icon-toggle-tip"></span>
      </div>
      <div class="knowledge-base-title">Knowledge base</div>
      <ul class="knowledge-base-list">
        <li *ngFor="let knowledgeBase of knowledgeBaseList" class="knowledge-base-item">
          <p class="title">{{ knowledgeBase.title }}</p>
          <p class="description">{{ knowledgeBase.description }}</p>
          <ul class="items">
            <li *ngFor="let item of knowledgeBase.items">
              <span [innerHTML]="item"></span>
              <span class="icon-copy" (click)="copyKnowledge(item)">
                <mat-icon svgIcon="copy"></mat-icon>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #knowledgeBaseTips>
  <div class="knowledge-base-tips" *ngIf="showKnowledgeTips">
    <span class="icon-close-tip" (click)="showKnowledgeTips = false"></span>
    <p class="knowledge-base-tip">Eager to get the full potential of AI?</p>
    <p class="knowledge-base-tip">Check out <span class="highlight">Knowledge base</span></p>
    <span class="arrow"></span>
  </div>
</ng-template>
