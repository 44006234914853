export interface KnowledgeBase {
  title: string;
  description: string;
  items: string[];
}

export interface KnowledgeBaseSection {
  [key: string]: KnowledgeBase[];
}

export interface Prompt {
  [key: string]: string;
}

export interface PDF {
  name: string;
  content: string;
  token: number;
}
