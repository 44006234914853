export interface Message {
  role: string;
  content: string;
  quickActions?: string[];
  selectedIndex?: number;
  isCopy?: boolean;
  isHidden?: boolean;
  parentQuickAction?: string;
}

export interface ChatsInfo {
  question?: string;
  prompt?: string;
  latest?: boolean; // if regenerate, set as true
}

export interface ChatsRes {
  answer: string;
}
