import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
} from '@opentelemetry/semantic-conventions';

import { environment } from './environments/environment';

if (environment.OTEL_RESOURCE_ATTRIBUTE_ENV !== 'local') {
  const resource = Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: environment.OTEL_RESOURCE_ATTRIBUTE_NAME,
      [ATTR_SERVICE_VERSION]: environment.OTEL_RESOURCE_ATTRIBUTE_SERVICE,
      // This constant can be changed with ATTR_DEPLOYMENT_ENVIRONMENT_NAME after experimental flag is removed
      [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: environment.OTEL_RESOURCE_ATTRIBUTE_ENV,
    }),
  );

  const provider = new WebTracerProvider({ resource });

  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: environment.OTEL_EXPORTER_OTLP_ENDPOINT,
        headers: { 'Access-Control-Allow-Credentials': false },
      }),
    ),
  );

  provider.register();

  registerInstrumentations({
    instrumentations: getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-document-load': {
        enabled: true,
      },
      '@opentelemetry/instrumentation-user-interaction': {
        enabled: true,
      },
      '@opentelemetry/instrumentation-fetch': {
        enabled: true,
      },
      '@opentelemetry/instrumentation-xml-http-request': {
        enabled: true,
      },
    }),
  });
}
