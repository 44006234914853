import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as Enums from '../../enums';
import * as Models from '../../models';
import { UploadComponent } from '../upload/upload.component';
import { environment } from '../../../environments/environment';
import { SharedModule } from '../../modules/shared.module';

enum FileName {
  firstFile = 'firstFile',
  secondFile = 'secondFile',
}

@Component({
  standalone: true,
  imports: [SharedModule, UploadComponent],
  selector: 'playground-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss'],
})
export class ComparisonComponent {
  static readonly maxToken = +environment.maxFileTokenSize / 2;
  static readonly maxTokenError = `${Enums.NoticeText.SizeError} ${ComparisonComponent.maxToken} tokens.`;
  uploadConfig1: Models.UploadConfig = {
    uploadTittle: 'File 1',
    maxSizeInBytes: 1024 * 1024 * 1024,
    notice: `The upload limit is based on the amount of text in the PDF, rather than the file size`,
    maxToken: ComparisonComponent.maxToken,
  };
  uploadConfig2: Models.UploadConfig = {
    uploadTittle: 'File 2',
    maxSizeInBytes: 1024 * 1024 * 1024,
    notice: `The upload limit is based on the amount of text in the PDF, rather than the file size`,
    maxToken: ComparisonComponent.maxToken,
  };
  uploaded1 = Enums.FileStatus.Empty;
  uploaded2 = Enums.FileStatus.Empty;
  fileName = FileName;
  noticeText = '';
  noticeErrorFile1 = '';
  noticeErrorFile2 = '';

  firstPDF!: Models.PDF;
  secondPDF!: Models.PDF;

  get allUploaded() {
    const re = this.uploaded1 === Enums.FileStatus.Success && this.uploaded2 === Enums.FileStatus.Success;
    if (re) {
      this.noticeText = Enums.NoticeText.Success;
    }
    return re;
  }
  get isFileFailed() {
    return this.uploaded1 === Enums.FileStatus.Error || this.uploaded2 === Enums.FileStatus.Error;
  }

  constructor(public dialogRef: MatDialogRef<ComparisonComponent>) {}

  fileChanged(file: File, fileName: string) {
    if (fileName === FileName.firstFile) {
      if (!file) {
        this.uploaded1 = Enums.FileStatus.Empty;
      } else if (this.firstPDF.token > ComparisonComponent.maxToken) {
        const tokenPercentage = this.getTokenPercentage(this.firstPDF.token);
        this.uploaded1 = Enums.FileStatus.Error;
        this.noticeErrorFile1 = `First file have ${tokenPercentage}% more text content than the maximum allowed limit`;
      } else {
        this.uploaded1 = Enums.FileStatus.Success;
      }
    } else if (fileName === FileName.secondFile) {
      if (!file) {
        this.uploaded2 = Enums.FileStatus.Empty;
      } else if (this.secondPDF.token > ComparisonComponent.maxToken) {
        const tokenPercentage = this.getTokenPercentage(this.secondPDF.token);
        this.uploaded2 = Enums.FileStatus.Error;
        this.noticeErrorFile2 = `Second file have ${tokenPercentage}% more text content than the maximum allowed limit`;
      } else {
        this.uploaded2 = Enums.FileStatus.Success;
      }
    }
  }

  handleFileError(error: Enums.FileErrorType, fileName: string) {
    if (fileName === FileName.firstFile) {
      this.uploaded1 = Enums.FileStatus.Error;
    } else if (fileName === FileName.secondFile) {
      this.uploaded2 = Enums.FileStatus.Error;
    }
    if (error === Enums.FileErrorType.SizeError) {
      this.noticeText = Enums.NoticeText.SizeError;
    }
  }

  closeDialog(status: boolean) {
    const PDFs: Models.PDF[] = [this.firstPDF, this.secondPDF];
    localStorage.setItem('PDFs', JSON.stringify(PDFs));
    this.dialogRef.close(status);
  }

  onPDFChange(PDF: Models.PDF, fileName: string) {
    if (fileName === FileName.firstFile) {
      this.firstPDF = PDF;
    } else if (fileName === FileName.secondFile) {
      this.secondPDF = PDF;
    }
  }

  getTokenPercentage(fileTokenSize: number) {
    const maxTokenLimit = ComparisonComponent.maxToken;
    const tokenPercentage = ((fileTokenSize - maxTokenLimit) / maxTokenLimit) * 100;
    return Math.round(tokenPercentage);
  }
}
