import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUploadModule } from 'ng-file-upload';
import * as Enums from '../../enums';
import * as Models from '../../models';
import { SharedModule } from '../../modules/shared.module';
import { PdfViewerComponent } from '../pdf-loader/pdf-loader.component';

enum ProgressIcons {
  Pointer1 = 'progress-pointer1',
  Pointer2 = 'progress-pointer2',
}

@Component({
  selector: 'playground-upload',
  standalone: true,
  imports: [CommonModule, FileUploadModule, SharedModule, PdfViewerComponent],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  static readonly completedProgress = 100;
  @ViewChild('inputFile') inputFileRef!: ElementRef;
  // only support single file upload
  @Input() config!: Models.UploadConfig;
  @Output() fileChanged = new EventEmitter<File>();
  @Output() fileErrorEvent = new EventEmitter<Enums.FileErrorType>();
  @Output() pdfChange = new EventEmitter<Models.PDF>();
  progress = 0;
  remainTime?: number;
  fileList: File[] = [];
  fileUrl = '';
  progressIcons = ProgressIcons;
  progressIcon = ProgressIcons.Pointer1;
  fileError = false;

  constructor() {
    setInterval(() => {
      if (this.progressIcon === ProgressIcons.Pointer1) {
        this.progressIcon = ProgressIcons.Pointer2;
      } else {
        this.progressIcon = ProgressIcons.Pointer1;
      }
    }, 500);
  }

  deleteFile() {
    const PDFs = JSON.parse(localStorage.getItem('PDFs') ?? '{}');
    delete PDFs[this.fileList[0].name];
    localStorage.setItem('PDFs', JSON.stringify(PDFs));
    this.inputFileRef.nativeElement.value = '';
    this.fileChanged.emit();
    this.fileList = [];
    this.fileError = false;
  }
  onChange($event: Event) {
    // handle uploaded file  here
    const inputElement = $event.target as HTMLInputElement;
    if (inputElement.files) {
      this.uploadFileList(inputElement.files);
    }
  }
  upload() {
    // upload file here
    if (this.fileList[0]) {
      return;
    }
    const inputElement = this.inputFileRef.nativeElement;
    (inputElement as HTMLInputElement).click();
  }

  fileDrop($event: File[]) {
    this.uploadFileList($event);
  }

  uploadFileList(fileList: FileList | File[]): void {
    if (fileList.length === 0) {
      return;
    }
    const fileSize = fileList[0].size;
    if (fileSize > this.config.maxSizeInBytes) {
      this.handleFileError(Enums.FileErrorType.SizeError);
      return;
    }
    if (this.config.supportType && this.config.supportType.length > 0) {
      const fileType = fileList[0].type;
      if (!this.config.supportType.includes(fileType)) {
        this.handleFileError(Enums.FileErrorType.TypeError);
        return;
      }
    }
    this.fileList.push(fileList[0]);
    this.fileError = false;
    this.readingFile(this.fileList[0]);
  }

  handleFileError(type: Enums.FileErrorType) {
    this.inputFileRef.nativeElement.value = '';
    this.fileError = true;
    this.fileErrorEvent.emit(type);
  }

  readingFile(file: File) {
    // read PDF file here
    this.progress = 0;
    this.fileUrl = URL.createObjectURL(file);
  }

  onReadingProgress({ progress, remainTime }: { progress: number; remainTime: number }) {
    this.progress = progress;
    this.remainTime = remainTime;
    if (progress === UploadComponent.completedProgress) {
      this.remainTime = undefined;
      setTimeout(() => {
        this.fileChanged.emit(this.fileList[0]);
      }, 1000);
    }
  }

  onPDFChange(pdf: Models.PDF): void {
    if (pdf.token > this.config.maxToken) {
      this.fileError = true;
    }
    this.pdfChange.emit(pdf);
  }
}
