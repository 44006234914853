import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Models from '../../models';
import * as Enums from '../../enums';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlaygroundService {
  constructor(private http: HttpClient) {}

  generate(chatsInfo: Models.ChatsInfo, chatSid: string): Observable<HttpResponse<Models.ChatsRes>> {
    return this.http.post<Models.ChatsRes>(`${environment.baseUrl}/${Enums.APIPath.Chats}`, chatsInfo, {
      headers: {
        'x-chat-sid': chatSid,
      },
      observe: 'response',
    });
  }
}
