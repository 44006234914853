export interface Environment {
  baseUrl: string;
  scope: string[];
  clientId: string;
  authority: string;
  maxFileTokenSize: string;
  feedbackMail: string;
  ELASTIC_RUM_ENDPOINT: string;
  OTEL_EXPORTER_OTLP_ENDPOINT: string;
  OTEL_RESOURCE_ATTRIBUTE_NAME: string;
  OTEL_RESOURCE_ATTRIBUTE_SERVICE: string;
  OTEL_RESOURCE_ATTRIBUTE_ENV: string;
}

export interface UploadConfig {
  uploadTittle: string;
  maxSizeInBytes: number;
  notice: string;
  supportType?: string;
  maxToken: number;
}

export interface UploadConfig {
  uploadTittle: string;
  maxSizeInBytes: number;
  notice: string;
  supportType?: string;
  byteSize?: string;
}
