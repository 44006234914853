<div
  ng2FileDrop
  class="henkel-gpt-file-upload"
  [ngClass]="{ disabled: fileList.length > 0, error: fileError }"
  (onFileDrop)="fileDrop($event)"
  (click)="upload()"
>
  <input
    #inputFile
    type="file"
    class="invisible absolute"
    (change)="onChange($event)"
    [accept]="config.supportType ?? '.pdf'"
  />
  <div class="upload-tittle" *ngIf="config.uploadTittle.length > 0">
    {{ config.uploadTittle }}
  </div>
  <div class="upload-content" *ngIf="fileList.length === 0">
    <mat-icon svgIcon="upload" class="upload-icon"></mat-icon>
    <div class="upload-label">Drag & drop or Choose PDF to upload</div>
    <div class="upload-notice">{{ config.notice }}</div>
  </div>
  <ul class="file-list" *ngIf="fileList.length > 0">
    <li class="file-item" *ngFor="let item of fileList">
      <mat-icon svgIcon="pdf" class="pdf-icon"></mat-icon>
      <div class="file-details">
        <div class="delete-button" (click)="deleteFile()">
          <span class="icon-close-bold"></span>
        </div>
        <div class="file-name">{{ item.name }}</div>
        <div class="file-status">
          <!-- <span *ngIf="item.isError" class="file-failed">failed</span> -->
          <!-- *ngIf="item.isSuccess" -->
          <span class="file-size">{{ item.size | byteSize }}</span>
          <span *ngIf="progress !== 100">
            <mat-icon svgIcon="dot" inline class="dot-icon"></mat-icon>
            <span class="upload-left-time" *ngIf="remainTime !== undefined"
              >{{ remainTime }}
              {{ remainTime > 1 ? 'seconds' : 'second' }}
              left</span
            >
            <span class="upload-progress">{{ progress }}%</span>
          </span>
        </div>
        <div class="progress-bar">
          <mat-progress-bar mode="determinate" [value]="progress"> </mat-progress-bar>
          <!-- [style]="{left:calc(progress% - size)}" -->
          <mat-icon
            class="progress-pointer"
            [ngStyle]="{
              left: 'calc(' + progress + '% - 1rem)',
              opacity: progressIcon === progressIcons.Pointer2 ? 1 : 0
            }"
            svgIcon="progress-pointer"
            inline
          ></mat-icon>
          <mat-icon
            class="progress-pointer"
            [ngStyle]="{
              left: 'calc(' + progress + '% - 1rem)',
              opacity: progressIcon === progressIcons.Pointer2 ? 0 : 1
            }"
            svgIcon="progress-pointer2"
            inline
          ></mat-icon>
        </div>
      </div>
    </li>
  </ul>
  <playground-pdf-viewer
    class="hidden-pdf-viewer"
    [pdfPath]="fileUrl"
    (readingProgress)="onReadingProgress($event)"
    [pdfName]="(fileList.length ? fileList[0].name : null) ?? ''"
    (pdfChange)="onPDFChange($event)"
  ></playground-pdf-viewer>
</div>
<!-- || existFiles.length > 0 -->
