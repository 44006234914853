import * as Models from '../app/models';

export const environment: Models.Environment = {
  baseUrl: 'https://dev.aiplayground.henkelgroup.cloud/api',
  scope: ['api://5cadeaeb-8252-4e09-9683-580133c5814b/user_impersonation/user_impersonation'],
  clientId: '5cadeaeb-8252-4e09-9683-580133c5814b',
  authority: 'https://login.microsoftonline.com/e8701075-0d9e-4ea1-991d-5a0d110a5d29',
  maxFileTokenSize: '9999999',
  feedbackMail: 'mailto:aiplayground@henkel.com?subject=Playground.AI%20Feedback',
  ELASTIC_RUM_ENDPOINT: 'https://observability.raqn.io',
  OTEL_EXPORTER_OTLP_ENDPOINT: 'https://observability.raqn.io/v1/traces',
  OTEL_RESOURCE_ATTRIBUTE_NAME: 'ai_playground_ui_rum',
  OTEL_RESOURCE_ATTRIBUTE_SERVICE: '1.0',
  OTEL_RESOURCE_ATTRIBUTE_ENV: 'dev',
};
