import { apm } from '@elastic/apm-rum';

import { environment } from './environments/environment';

if (environment.OTEL_RESOURCE_ATTRIBUTE_ENV !== 'local') {
  apm.init({
    serviceName: environment.OTEL_RESOURCE_ATTRIBUTE_NAME,
    serverUrl: environment.ELASTIC_RUM_ENDPOINT,
    serviceVersion: '1.0',
    logLevel: 'error',
    environment: environment.OTEL_RESOURCE_ATTRIBUTE_ENV,
  });
}
