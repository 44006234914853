import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Observable, Subject, catchError, filter, map, of, switchMap, takeUntil } from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';
import * as Constants from '../../constants';
import * as Enums from '../../enums';
import * as Mocks from '../../mocks';
import * as Models from '../../models';
import { SharedModule } from '../../modules/shared.module';
import { ErrorService } from '../../services/error/error.service';
import { MarkedService } from '../../services/marked/marked.service';
import { PlaygroundService } from '../../services/playground/playground.service';

@Component({
  standalone: true,
  imports: [SharedModule, HeaderComponent],
  selector: 'playground-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly autoTextareaMaxHeight = 300;
  static readonly enterCode = 'Enter';
  static readonly splitFlag = '##';
  @ViewChild('messageList') messageList!: ElementRef;
  @ViewChild('autoTextarea') autoTextarea!: ElementRef<HTMLTextAreaElement>;
  playgroundType = '';
  knowledgeBaseList: Models.KnowledgeBase[] = [];
  role = Enums.ChatRole;
  messages: Models.Message[] = Mocks.MessageList;
  message = '';
  isLoading = true;
  showKnowledgeTips = false;
  destroyed$ = new Subject<void>();
  messageList$!: Observable<Models.Message[]>;
  messageListDisplay: Models.Message[] = []; // include greeting
  #newMessageFilter$ = new BehaviorSubject(true);
  newMessageFilter$: Observable<boolean> = this.#newMessageFilter$.asObservable();
  isInit = true;
  chatSid = '';
  quickAction = '';
  messagePlaceholder = Enums.MessagePlaceholder.General;
  isRegenerate = false;
  isSimilarity = false;

  constructor(
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private markedService: MarkedService,
    private playgroundService: PlaygroundService,
    private errorService: ErrorService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    this.subscribeToQueryParams();
  }

  ngAfterViewInit(): void {
    this.initAutoTextarea();
    this.initTableAction();
    this.focusTextarea();
  }

  focusTextarea() {
    if (this.autoTextarea) {
      this.autoTextarea.nativeElement.focus();
    } else {
      console.error('autoTextarea is not defined');
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private subscribeToQueryParams(): void {
    this.messageList$ = this.route.queryParams.pipe(
      takeUntil(this.destroyed$),
      switchMap((params: Params) => this.handleRouteParams(params)),
    );
  }

  private handleRouteParams(params: Params): Observable<Models.Message[]> {
    this.messageListDisplay = [];
    this.playgroundType = params[Enums.QueryParams.Type] ?? Enums.PlaygroundType.Compose;
    this.knowledgeBaseList = Constants.KnowledgeBaseList[this.playgroundType];
    this.initGreetings();
    this.isLoading = true;

    return this.newMessageFilter$.pipe(
      filter((data) => data),
      switchMap(() => this.handleNewMessageFilter()),
    );
  }

  private handleNewMessageFilter(): Observable<Models.Message[]> {
    if (this.isInit) {
      this.isInit = false;
      this.chatSid = '';
      if (this.playgroundType === Enums.PlaygroundType.Summarise) {
        this.messageListDisplay.push({
          role: Enums.ChatRole.User,
          content: '',
          isHidden: true,
        });
        this.#newMessageFilter$.next(true);
        return of(this.messageListDisplay);
      } else {
        this.isLoading = false;
        setTimeout(() => {
          this.focusTextarea();
        }, 0);
        return of(this.messageListDisplay);
      }
    }

    return this.playgroundService
      .generate(
        {
          question: this.isRegenerate ? undefined : this.messageListDisplay[this.messageListDisplay.length - 1].content,
          prompt: this.prompt,
          latest: this.isRegenerate ? true : undefined,
        },
        this.chatSid,
      )
      .pipe(
        takeUntil(this.destroyed$),
        map((httpResponse: HttpResponse<Models.ChatsRes>) => this.processResponse(httpResponse)),
        catchError(this.handleError.bind(this)),
      );
  }

  private processResponse(httpResponse: HttpResponse<Models.ChatsRes>): Models.Message[] {
    if (this.isRegenerate) {
      this.isRegenerate = false;
    }
    this.chatSid = httpResponse.headers.get('x-chat-sid') ?? '';
    const { answer } = (httpResponse.body || {}) as Models.ChatsRes;
    if (answer.includes(ChatComponent.splitFlag)) {
      this.handleQuickAction(answer);
    } else {
      this.messageListDisplay.push({
        role: Enums.ChatRole.Assistant,
        content: answer,
      });
    }
    this.isLoading = false;
    setTimeout(() => {
      this.focusTextarea();
    }, 0);
    this.goToLatestMessage();
    return this.messageListDisplay;
  }

  private handleError(err: HttpErrorResponse): Observable<Models.Message[]> {
    this.errorService.handleHttpError('AIGenerator', undefined);

    this.snackbar.open('Failed to connect with your assistant. Please try again.', 'Close', {
      duration: 5000,
    });

    this.isLoading = false;
    setTimeout(() => {
      this.focusTextarea();
    }, 0);
    return of(this.messageListDisplay);
  }

  get prompt(): string {
    const pdfs = JSON.parse(localStorage.getItem('PDFs') ?? '[]') as Models.PDF[];
    switch (this.playgroundType) {
      case Enums.PlaygroundType.Compare:
        return `${
          this.isSimilarity
            ? Constants.PromptList[Enums.CompareType.Similarities]
            : Constants.PromptList[Enums.CompareType.Differences]
        } First file name: ${pdfs[0].name} \n First file content: ${pdfs[0].content} \n Second file name: ${
          pdfs[1].name
        } \n Second file content: ${pdfs[1].content} \n\n\n\n{chat_history} Human: {question} AI:`;
      case Enums.PlaygroundType.Summarise:
        return `${Constants.PromptList[this.playgroundType]} File name: ${pdfs[0].name} \n File content: ${
          pdfs[0].content
        } \n\n\n\n{chat_history} Human: {question} AI:`;
      default:
        return Constants.PromptList[this.playgroundType] ?? '';
    }
  }

  handleQuickAction(content: string): void {
    const startIndex = content.indexOf(ChatComponent.splitFlag);
    const endIndex = content.lastIndexOf(ChatComponent.splitFlag);

    const quickActions = content
      .substring(startIndex, endIndex + ChatComponent.splitFlag.length)
      .replace(/\n/g, '')
      .split(ChatComponent.splitFlag)
      .filter((item) => item);

    const contentWithoutQuickActions =
      content.substring(0, startIndex) + content.substring(endIndex + ChatComponent.splitFlag.length, content.length);

    if (contentWithoutQuickActions.trim().length > 0) {
      this.messageListDisplay.push({
        role: Enums.ChatRole.Assistant,
        content: contentWithoutQuickActions,
        isCopy: true,
      });
    }

    let quickActionGreeting = '';
    switch (this.quickAction) {
      case Enums.QuickActionType.Compose:
        quickActionGreeting = 'You can always ask me to iterate on this, for example:';
        break;
      case Enums.QuickActionType.Reply:
        quickActionGreeting =
          'You can always ask me to adjust any part of it. Feel free to look at the Knowledge base or use any of these examples:';
        break;
      default:
        quickActionGreeting = 'Let me know what else I can do for you, for example:';
    }

    this.messageListDisplay.push({
      role: Enums.ChatRole.Assistant,
      content: quickActionGreeting,
      quickActions,
    });

    this.showKnowledgeTips = true;
  }

  initGreetings(): void {
    switch (this.playgroundType) {
      case Enums.PlaygroundType.Compose:
        {
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content:
              'Hey I’m <span class="bold">Playground</span>, your personal <span class="bold">AI assistant</span>. How can I help you?',
            quickActions: [Enums.QuickActionType.Reply, Enums.QuickActionType.Compose],
          });
        }
        break;
      case Enums.PlaygroundType.Compare:
        {
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content:
              'Hey I’m <span class="bold">Playground</span>, your personal <span class="bold">AI assistant</span>. Let me check PDF you have uploaded',
          });
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content: 'What do you want to see:',
            quickActions: [Enums.QuickActionType.Differences, Enums.QuickActionType.Similarities],
          });
        }
        break;
      case Enums.PlaygroundType.Summarise:
        {
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content:
              'Hey I’m <span class="bold">Playground</span>, your personal <span class="bold">AI assistant</span>. Let me summarize your file for you. ',
          });
        }
        break;
      case Enums.PlaygroundType.FreePlay:
        {
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content:
              'Hey I’m <span class="bold">Playground</span>, your personal <span class="bold">AI assistant</span>. How can I help you?',
          });
          this.messageListDisplay.push({
            role: Enums.ChatRole.Assistant,
            content: `Please enter your prompt below, and I'll do my best to provide you with the information or assistance you need.`,
          });
        }
        break;
      default:
        break;
    }
  }

  initTableAction(): void {
    this.messageList?.nativeElement.addEventListener('click', (event: Event) => {
      const target = event.target as HTMLElement;

      if (target.classList.contains('table-action-export')) {
        const table = target.querySelector('.copy-table') as HTMLTableElement;
        this.markedService.exportAsCSV(table);
      }

      if (target.classList.contains('table-action-copy')) {
        const table = target.querySelector('.copy-table') as HTMLTableElement;
        this.copy(this.markedService.transferTableToText(table));
      }
    });
  }

  initAutoTextarea(reset = false): void {
    const textarea = document.getElementById('autoTextarea');
    const messageElement = this.messageList?.nativeElement as HTMLElement;

    if (reset && textarea) {
      textarea.style.height = '20px'; // set init height
      messageElement.style.paddingBottom = '30px'; // set init paddingBottom
    } else {
      // Add an input event listener to adjust the autoTextarea height
      textarea?.addEventListener('input', () => {
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = textarea.scrollHeight + 'px'; // Set the height to match the content

        // update messageList's padding-bottom when autoTextarea's height update
        const textareaHeight =
          textarea.scrollHeight <= ChatComponent.autoTextareaMaxHeight
            ? textarea.scrollHeight
            : ChatComponent.autoTextareaMaxHeight;
        messageElement.style.paddingBottom = textareaHeight + 'px';
      });
    }

    this.goToLatestMessage();
  }

  copy(content: string): void {
    this.clipboard.copy(content);
    this.snackbar.open('Text copied', 'Close');
  }

  copyKnowledge(content: string): void {
    const newContent = content.replace(/<span class="prompt-word">/g, '').replace(/<\/span>/g, '');
    this.copy(newContent);
  }

  goToLatestMessage(): void {
    setTimeout(() => {
      if (this.messageList != null) {
        this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
        this.messageList.nativeElement.style.scrollBehavior = 'smooth';
      }
    }, 0);
  }

  onAutoTextareaKeyup(event: KeyboardEvent): void {
    if (event && event.code === ChatComponent.enterCode && !event.shiftKey) {
      event.preventDefault();
      this.getAnswer();
    }
  }

  getAnswer(): void {
    if (this.message.length <= 0 || this.isLoading) {
      return;
    }
    this.messageListDisplay.push({
      role: Enums.ChatRole.User,
      content: this.message,
    });
    this.message = '';
    this.initAutoTextarea(true);
    this.#newMessageFilter$.next(true);
    this.isLoading = true;
  }

  toggleDrawer(drawer: MatDrawer): void {
    drawer.open();
    this.showKnowledgeTips = false;
  }

  formatAnswer(message: string): string {
    return this.markedService.formatTable(message);
  }

  formatUserAnswer(message: string): string {
    return this.markedService.formatUserAnswer(message);
  }

  sendQuickAction(
    messageIndex: number,
    quickActionIndex: number,
    quickAction: string,
    parentQuickAction?: string,
  ): void {
    if (this.isLoading) {
      return;
    }
    if (Object.values(Enums.QuickActionType).includes(quickAction as Enums.QuickActionType)) {
      this.quickAction = quickAction;
    }
    this.messageListDisplay[messageIndex].selectedIndex = quickActionIndex;

    this.messagePlaceholder =
      quickAction === Enums.QuickActionType.Reply
        ? Enums.MessagePlaceholder.ReplyEmail
        : Enums.MessagePlaceholder.General;

    if (quickAction === Enums.QuickActionType.Differences || quickAction === Enums.QuickActionType.Similarities) {
      this.isSimilarity = quickAction === Enums.QuickActionType.Similarities;
      this.isLoading = true;
      this.goToLatestMessage();
      setTimeout(() => {
        this.isLoading = false;
        setTimeout(() => {
          this.focusTextarea();
        }, 0);
        this.messageListDisplay.push({
          role: Enums.ChatRole.Assistant,
          content: `Sure! How would you like to view the ${
            quickAction === Enums.QuickActionType.Differences ? 'differences' : 'similarities'
          }: in a table or as text?`,
          quickActions: [Enums.QuickActionType.TableView, Enums.QuickActionType.TextView],
          parentQuickAction: quickAction,
        });
        this.goToLatestMessage();
      }, 3000);
      return;
    }
    this.messageListDisplay.push({
      role: Enums.ChatRole.User,
      content: parentQuickAction ? `${parentQuickAction} in ${quickAction}` : quickAction,
      isHidden: true,
    });
    this.#newMessageFilter$.next(true);
    this.isLoading = true;
    this.goToLatestMessage();
  }

  onNewDialogChange(): void {
    this.messageListDisplay = [];
    this.initGreetings();
    this.isInit = true;
    this.isLoading = true;
    this.#newMessageFilter$.next(true);
  }

  regenerate(): void {
    this.isRegenerate = true;

    const popMessage = this.messageListDisplay.pop();
    if (popMessage?.quickActions && popMessage?.quickActions.length > 0) {
      this.messageListDisplay.pop();
    }

    this.isLoading = true;
    this.goToLatestMessage();

    this.#newMessageFilter$.next(true);
  }
}
