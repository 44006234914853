<div class="dialog-container comparison-container">
  <button (click)="closeDialog(false)" class="close-dialog">
    <mat-icon svgIcon="close" inline></mat-icon>
  </button>
  <h1 mat-dialog-title>Upload two PDF files to compare</h1>
  <div mat-dialog-content>
    Upload two PDF files, and Playground.AI will analyze them and can answer any question you have about the content
    inside.
  </div>
  <div class="files-upload">
    <playground-upload
      [config]="uploadConfig1"
      (fileChanged)="fileChanged($event, fileName.firstFile)"
      (fileErrorEvent)="handleFileError($event, fileName.firstFile)"
      (pdfChange)="onPDFChange($event, fileName.firstFile)"
      class="file-upload"
    ></playground-upload>
    <mat-icon svgIcon="exchange" class="exchange-icon"></mat-icon>
    <playground-upload
      [config]="uploadConfig2"
      (fileChanged)="fileChanged($event, fileName.secondFile)"
      (fileErrorEvent)="handleFileError($event, fileName.secondFile)"
      (pdfChange)="onPDFChange($event, fileName.secondFile)"
      class="file-upload"
    ></playground-upload>
  </div>
  <div class="notice-wrapper" [ngClass]="{ error: isFileFailed }" *ngIf="allUploaded || isFileFailed">
    <div *ngIf="allUploaded" inline>
      <mat-icon svgIcon="check" class="notice-icon"></mat-icon>
      {{ noticeText }}
    </div>
    <div *ngIf="uploaded1 === 'error' && noticeErrorFile1">
      <mat-icon svgIcon="error" inline class="notice-icon"></mat-icon>{{ noticeErrorFile1 }}
    </div>
    <div *ngIf="uploaded2 === 'error' && noticeErrorFile2" style="margin-top: 2px">
      <mat-icon svgIcon="error" inline class="notice-icon"></mat-icon>{{ noticeErrorFile2 }}
    </div>
  </div>
  <button (click)="closeDialog(true)" class="compare-button" [disabled]="!allUploaded">Compare</button>
</div>
