import { ComponentType } from '@angular/cdk/overlay';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ComparisonComponent } from '../../components/comparison/comparison.component';
import { HeaderComponent } from '../../components/header/header.component';
import { SummaryComponent } from '../../components/summary/summary.component';
import * as Enums from '../../enums';
import { SharedModule } from '../../modules/shared.module';
import { environment } from '../../../environments/environment';

@Component({
  standalone: true,
  imports: [SharedModule, HeaderComponent],
  selector: 'playground-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  playgroundType = Enums.PlaygroundType;
  destroy$ = new Subject<void>();

  feedbackMail = environment.feedbackMail;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goToPlayground(type: string): void {
    this.router.navigate([Enums.RoutePath.Playground], {
      queryParams: { [Enums.QueryParams.Type]: type },
    });
  }

  scenarioChange(scenario: Enums.PlaygroundType): void {
    switch (scenario) {
      case Enums.PlaygroundType.Compare:
        {
          const dialogConfig = {
            panelClass: 'comparison-dialog',
            disableClose: true,
            width: '920px',
          };
          this.showDialog(ComparisonComponent, dialogConfig, Enums.PlaygroundType.Compare);
        }
        break;
      case Enums.PlaygroundType.Summarise:
        {
          const dialogConfig = {
            panelClass: 'summary-dialog',
            disableClose: true,
            width: '570px',
          };
          this.showDialog(SummaryComponent, dialogConfig, Enums.PlaygroundType.Summarise);
        }
        break;
      case Enums.PlaygroundType.FreePlay:
        break;
      default:
        break;
    }
  }

  showDialog(targetComponent: ComponentType<any>, config?: MatDialogConfig, scenario?: Enums.PlaygroundType): void {
    const dialog = this.dialog.open(targetComponent, config);
    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.router.navigate([Enums.RoutePath.Playground], {
            queryParams: { [Enums.QueryParams.Type]: scenario },
          });
        } else {
          localStorage.removeItem('PDFs');
        }
      });
  }
}
