<div class="header" [ngClass]="{ 'playground-header': isPlayground }">
  <div class="logo">
    <div class="title-info" (click)="goHome()">
      <span class="icon-logo"></span>
      <span class="title">AI Playground</span>
    </div>

    <div *ngIf="false" class="beta-info" (click)="showBetaInfo()">Beta <span class="icon-info"></span></div>
    <div class="beta-info-detail" [ngClass]="{ 'beta-info-hidden': isBetaInfoHidden }">
      <div class="triangle"></div>
      <img class="beta-info-logo" src="assets/images/beta-info-logo.svg" alt="beta info logo" />
      <div class="beta-info-title">Playground.AI Beta Phase</div>
      <div class="beta-info-description">
        <p class="following">Playground.AI is still under development so please note the following:</p>
        <ul class="items">
          <li>
            Our knowledge base depends on the training data of the underlying large language model (llm).<span
              class="highlight"
            >
              Real-time updates are not available.</span
            >
          </li>
          <li>
            You may encounter occasional <span class="highlight">unexpected behavior</span>, like rare crashes or
            certain features not performing as expected.
          </li>
        </ul>
        <p class="contact-us">
          Your feedback is invaluable in our quest to enhance your experience. If you encounter any issues, please
          <a class="link" [href]="feedbackMail">contact us</a>.
        </p>
      </div>
    </div>
    <div
      class="beta-info-menu-backdrop"
      (click)="hideBetaInfo()"
      [ngClass]="{ 'beta-info-hidden': isBetaInfoHidden }"
    ></div>
  </div>
  <!-- User Info and Logout Button -->
  <ng-container *ngIf="isLoggedIn && !isPlayground && userEmail">
    <div class="user-info">
      <span class="user-email">{{ userEmail }}</span>
      <button class="logout-button" (click)="logout()">Logout</button>
    </div>
  </ng-container>
  <div class="actions" *ngIf="isPlayground">
    <button
      class="btn-new-dialog"
      [ngClass]="{ active: !isMenuClosed }"
      [matMenuTriggerFor]="menu"
      (click)="isMenuClosed = false"
    >
      New dialog
    </button>
  </div>
</div>

<mat-menu #menu="matMenu" class="new-dialog-menu" (closed)="isMenuClosed = true">
  <button (click)="changeScenario(scenarioList.Compose)" mat-menu-item>
    <span class="menu-icon icon-email"></span>
    Compose email
  </button>
  <button (click)="changeScenario(scenarioList.Compare)" mat-menu-item>
    <span class="menu-icon icon-compare-mini"></span>
    Compare documents
  </button>
  <button (click)="changeScenario(scenarioList.Summarise)" mat-menu-item>
    <span class="menu-icon icon-document"></span>
    Summarize document
  </button>
  <button (click)="changeScenario(scenarioList.FreePlay)" mat-menu-item>
    <span class="menu-icon icon-free-play-new-dialog"></span>
    Free play with prompts
  </button>
</mat-menu>
