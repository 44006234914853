import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Service for generic error handlers.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  /**
   * Handle all HTTP call errors in the same way.
   */

  constructor(private snackBar: MatSnackBar) {}

  handleHttpError(
    operation = 'operation',
    msg?: string,
    callback?: (error: HttpErrorResponse) => void,
  ): (e: HttpErrorResponse) => never {
    return (error: HttpErrorResponse): never => {
      console.error('Error Service caught HTTP error:::', error);
      if (callback !== undefined) {
        callback(error);
      }

      if (error.error.statusCode === 400) {
        this.snackBar.open(error.error.message, 'Close');
      }

      const message = msg
        ? msg
        : error.error instanceof ErrorEvent
        ? // A client-side or network error occurred.
          error.error.message
        : // A server-side or network error occurred.
          // `Server returned code ${error.status} with body "${JSON.stringify(error.error)}"`;
          `Server returned code ${error.status} with body "${error.error.message}"`;
      throw new Error(`Error Service throws: ${operation} failed::: ${message}`);
    };
  }
}
