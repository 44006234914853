import { ComponentType } from '@angular/cdk/overlay';
import { Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import * as Enums from '../../enums';
import { SharedModule } from '../../modules/shared.module';
import { ComparisonComponent } from '../comparison/comparison.component';
import { SummaryComponent } from '../summary/summary.component';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'playground-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private readonly destroyRef = inject(DestroyRef);
  @Input() isPlayground = false;
  isMenuClosed = true;
  scenarioList = Enums.PlaygroundType;
  @Output() newDialogChange = new EventEmitter();
  isBetaInfoHidden = true;
  feedbackMail = environment.feedbackMail;
  userEmail: string | null = null;
  isLoggedIn = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: MsalService,
  ) {
    this.userEmail = this.getUserEmail();
    this.isLoggedIn = this.getIsLoggedIn();
  }

  getUserEmail(): string | null {
    const account: AccountInfo | null = this.authService.instance.getActiveAccount();
    return account?.username ?? null;
  }

  getIsLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  logout(): void {
    this.authService.logoutRedirect();
  }

  goHome(): void {
    this.router.navigate([Enums.RoutePath.Home]);
  }

  changeScenario(scenario: Enums.PlaygroundType): void {
    switch (scenario) {
      case Enums.PlaygroundType.Compare:
        {
          const dialogConfig = {
            panelClass: 'comparison-dialog',
            disableClose: true,
            width: '920px',
          };
          this.showDialog(ComparisonComponent, dialogConfig, scenario);
        }
        break;
      case Enums.PlaygroundType.Summarise:
        {
          const dialogConfig = {
            panelClass: 'summary-dialog',
            disableClose: true,
            width: '570px',
          };
          this.showDialog(SummaryComponent, dialogConfig, scenario);
        }
        break;
      default:
        this.router
          .navigate([Enums.RoutePath.Playground], {
            queryParams: { [Enums.QueryParams.Type]: scenario },
          })
          .then(() => this.newDialog(scenario));
        break;
    }
  }

  showDialog(targetComponent: ComponentType<any>, config: MatDialogConfig, scenario: Enums.PlaygroundType): void {
    const dialog = this.dialog.open(targetComponent, config);
    dialog
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (!data) {
          localStorage.removeItem('PDFs');
        } else {
          this.router
            .navigate([], {
              queryParams: { type: scenario },
            })
            .then(() => this.newDialog(scenario));
        }
      });
  }

  newDialog(newType: string): void {
    const currentType = this.route.snapshot.queryParams[Enums.QueryParams.Type];
    if (currentType === newType) {
      this.newDialogChange.emit();
    }
  }

  showBetaInfo(): void {
    this.isBetaInfoHidden = false;
  }

  hideBetaInfo(): void {
    this.isBetaInfoHidden = true;
  }
}
