export enum FileErrorType {
  SizeError = 'SizeError',
  TypeError = 'TypeError',
}

export enum FileStatus {
  Success = 'success',
  Error = 'error',
  Empty = 'empty',
}

export enum NoticeText {
  Success = 'Your file has been uploaded successfully.',
  SizeError = 'PDF file exceeds token size limit of ',
}
