export const APIVersion = '2023-03-15-preview';

// export const DefaultChatConfig: Models.ChatRequestData = {
//     messages: [],
//     max_tokens: 800,
//     temperature: 0,
//     frequency_penalty: 0,
//     presence_penalty: 0,
//     top_p: 0.95,
//     stop: null,
// };
