import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  addIcon() {
    this.iconRegistry.addSvgIcon(
      'close',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-close.svg'),
    );
    this.iconRegistry.addSvgIcon('pdf', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-pdf.svg'));
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-upload.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'exchange',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-exchange.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'progress-pointer',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-progress-pointer.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'progress-pointer2',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-progress-pointer2.svg'),
    );
    this.iconRegistry.addSvgIcon('dot', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-dot.svg'));
    this.iconRegistry.addSvgIcon('copy', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-copy.svg'));
    this.iconRegistry.addSvgIcon(
      'check',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-check.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'error',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-error.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'refresh',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-refresh.svg'),
    );
    this.iconRegistry.addSvgIcon('gpt', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-gpt.svg'));
    this.iconRegistry.addSvgIcon(
      'guideline-gpt',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-guideline-gpt.svg'),
    );
  }
}
