<div class="dialog-container summary-container">
  <button (click)="closeDialog(false)" class="close-dialog">
    <mat-icon svgIcon="close" inline></mat-icon>
  </button>
  <h1 mat-dialog-title>Upload a PDF file to summarize</h1>
  <div mat-dialog-content>Upload any PDF file, and Playground.AI will analyze it, providing you with a summary.</div>
  <div class="files-upload">
    <playground-upload
      [config]="uploadConfig"
      (fileChanged)="fileChanged($event)"
      (fileErrorEvent)="handleFileError($event)"
      (pdfChange)="onPDFChange($event)"
      class="file-upload"
    ></playground-upload>
  </div>
  <div
    class="notice-wrapper"
    [ngClass]="{ error: uploaded === fileStatus.Error }"
    *ngIf="uploaded !== fileStatus.Empty"
    style="display: flex"
  >
    <mat-icon *ngIf="uploaded === fileStatus.Success" svgIcon="check" inline class="notice-icon"></mat-icon>
    <mat-icon *ngIf="uploaded === fileStatus.Error" svgIcon="error" inline class="notice-icon"></mat-icon>
    <div style="width: 380px">
      {{ noticeText }}
    </div>
  </div>
  <button (click)="closeDialog(true)" class="compare-button" [disabled]="uploaded !== fileStatus.Success">
    Summarize
  </button>
</div>
