import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteSize',
})
export class ByteSizePipe implements PipeTransform {
  transform(bytes: number, precision = 1): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    return bytes.toFixed(+precision) + ' ' + units[unitIndex];
  }
}
