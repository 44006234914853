export enum PlaygroundType {
  Compose = 'compose-emails',
  Compare = 'compare-documents',
  Summarise = 'summarise-document',
  FreePlay = 'free-play',
}

export enum CompareType {
  Differences = 'Differences',
  Similarities = 'Similarities',
}

export enum QuickActionType {
  Reply = '👉 Reply on a given email',
  Compose = '👉 Compose email',
  Differences = '👉 Differences',
  Similarities = '👉 Similarities',
  TableView = '👉 Table view (divided into rows and sections)',
  TextView = '👉 Text (list of bullet point)',
}

export enum ChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export enum MessagePlaceholder {
  General = 'Enter your message',
  ReplyEmail = 'Paste your given email here',
}
