import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileDropDirective } from './file-upload/file-drop.directive';
import { FileSelectDirective } from './file-upload/file-select.directive';
export { FileDropDirective } from './file-upload/file-drop.directive';
export { FileSelectDirective } from './file-upload/file-select.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FileDropDirective, FileSelectDirective],
  exports: [FileDropDirective, FileSelectDirective],
})
export class FileUploadModule {}
