import * as Enums from '../enums';
import * as Models from '../models';

export const MessageList: Models.Message[] = [
  {
    role: Enums.ChatRole.Assistant,
    content: 'Hey I’m Playground, your personal AI assistant. How can I help you?',
    quickActions: ['👉  Reply on a given email', '👉  Compose email'],
  },
  {
    role: Enums.ChatRole.User,
    content:
      'Help me to reply this email: Hello Ivan, we can have a short call today when you are free for planning, you can skip the Sprint planning call as it is reduced to 30 mins, from 10:00 to 10:30 Best Regards, Omkar Ray',
  },
  {
    role: Enums.ChatRole.Assistant,
    content: `Sure! Here are three tables with three columns (name, age, sex) about six students each:\n\nTable 1:\n| Name   | Age | Sex |\n|--------|-----|-----|\n| John   | 20  | M   |\n| Sarah  | 19  | F   |\n| David  | 21  | M   |\n| Emily  | 18  | F   |\n| Michael| 22  | M   |\n| Emma   | 20  | F   |\n\nTable 2:\n| Name   | Age | Sex |\n|--------|-----|-----|\n| James  | 23  | M   |\n| Lily   | 19  | F   |\n| Ethan  | 20  | M   |\n| Olivia | 21  | F   |\n| Benjamin| 19 | M   |\n| Ava    | 22  | F   |\n\nTable 3:\n| Name   | Age | Sex |\n|--------|-----|-----|\n| Daniel | 20  | M   |\n| Sophia | 19  | F   |\n| Matthew| 21  | M   |\n| Chloe  | 18  | F   |\n| Andrew | 22  | M   |\n| Mia    | 20  | F   |`,
  },
  {
    role: Enums.ChatRole.User,
    content:
      'Help me to reply this email: Hello Ivan, we can have a short call today when you are free for planning, you can skip the Sprint planning call as it is reduced to 30 mins, from 10:00 to 10:30 Best Regards, Omkar Ray',
  },
  {
    role: Enums.ChatRole.Assistant,
    content: 'Hey I’m Playground, your personal AI assistant. How can I help you?',
  },
  {
    role: Enums.ChatRole.User,
    content:
      'Help me to reply this email: Hello Ivan, we can have a short call today when you are free for planning, you can skip the Sprint planning call as it is reduced to 30 mins, from 10:00 to 10:30 Best Regards, Omkar Ray',
  },
  {
    role: Enums.ChatRole.Assistant,
    content: 'Hey I’m Playground, your personal AI assistant. How can I help you?',
  },
  {
    role: Enums.ChatRole.User,
    content:
      'Help me to reply this email: Hello Ivan, we can have a short call today when you are free for planning, you can skip the Sprint planning call as it is reduced to 30 mins, from 10:00 to 10:30 Best Regards, Omkar Ray',
  },
];
