import { Component } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';
import * as Enums from '../../enums';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'playground-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  continue(): void {
    localStorage.setItem(Enums.DisclaimerKey.Disclaimer, Enums.DisclaimerValue.Visited);
  }
}
