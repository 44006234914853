import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-placeholder',
  template: ` <div class="placeholder">
    <div class="message">
      <h1>WE'RE OFFLINE</h1>
      <p>
        AI-Playground is taking a break. <br />
        We’re building something <b>even more exciting </b> for you. <br />
        Stay tuned for updates!
      </p>
      <p><b> See you soon!</b></p>
    </div>
  </div>`,
  styles: [
    `
      .placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100vh;

        color: black;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('/assets/images/backgorund_AI_playgound.gif');
        .message {
          font-size: 50px;
          max-width: 800px;
          margin: auto;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
        .message h1 {
          font-size: 40px;
          margin-bottom: 10px;
        }
        .message p {
          margin-top: 20px;
          font-size: 30px;
        }
      }
    `,
  ],
})
export class PlaceholderComponent {}
