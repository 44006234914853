import { Route } from '@angular/router';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';

export const appRoutes: Route[] = [
  // {
  //   path: '',
  //   redirectTo: Enums.RoutePath.Home,
  //   pathMatch: 'full',
  // },
  // {
  //   path: Enums.RoutePath.Home,
  //   component: HomeComponent,
  //   canActivate: [MsalGuard],
  // },
  // {
  //   path: Enums.RoutePath.Playground,
  //   component: ChatComponent,
  //   canActivate: [MsalGuard],
  // },
  // {
  //   path: Enums.RoutePath.Unauthorized,
  //   component: UnauthorizedComponent,
  //   canActivate: [MsalGuard],
  // },
  // {
  //   path: 'auth',
  //   component: MsalRedirectComponent,
  // },
  // {
  //   path: '**',
  //   redirectTo: Enums.RoutePath.Home,
  //   pathMatch: 'full',
  // },
  {
    path: '**',
    component: PlaceholderComponent,
  },
];
