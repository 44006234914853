<h1 mat-dialog-title>
  Welcome to&nbsp;<span class="disclaimer-playground">Playground.</span><span class="disclaimer-ai">AI</span>
</h1>
<div mat-dialog-content>
  <div class="disclaimer-item">
    <mat-icon svgIcon="gpt" class="disclaimer-icon"></mat-icon>
    <div class="disclaimer-content">
      <p class="title">Powered by latest models from Open AI</p>
      <p class="description">
        Playground.AI is
        <span class="highlight">powered by the newest and most powerful language model from OpenAI</span>. And we will
        constantly adopt latest advances in AI. This means you can expect event better results from our tool over time.
      </p>
    </div>
  </div>
  <div class="disclaimer-item">
    <mat-icon svgIcon="guideline-gpt" class="disclaimer-icon"></mat-icon>
    <div class="disclaimer-content">
      <p class="title">Guideline for using Playground.AI</p>
      <p class="description">
        Playground.AI using Azure OpenAI Service is operated by Microsoft. That means your input (prompts) and output
        (completions) are NOT available to OpenAI or other Azure customers. Do not share
        <span class="highlight">any Henkel confidential information</span> with Playground.AI! This includes trade
        secrets, customer data, and personal data. Such information must not be sent to any AI models (entered through
        the chat interface) as we would lose control over these data. Please read carefully the
        <a
          href="https://henkelgroup.sharepoint.com/sites/SharedResponsibility/SitePages/Bewusstes-Umgehen-mit-ChatGPT.aspx"
          style="text-decoration: underline; color: blue; outline: none"
        >
          “Usage Guidelines for Artificial Intelligence”: Artificial intelligence in everyday life: conscious
          application</a
        >
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <p class="tips">
    By clicking "Continue", I acknowledge and agree to not share any confidential or strictly confidential information.
  </p>
  <button mat-dialog-close (click)="continue()" class="btn-dialog">Continue</button>
</div>
