export enum APIPath {
  Chat = 'chat',
  Completions = 'completions',
  Chats = 'chats',
}

export enum QueryParams {
  APIVersion = 'api-version',
  Type = 'type',
  AppId = 'appId',
}
