<div class="home-page">
  <div class="home-header">
    <playground-header></playground-header>
  </div>
  <div class="home-content">
    <div class="title">
      <span class="title-normal">Time flies when you</span>
      <span class="title-playground"><span class="title-play"> Play</span>ground. </span>
      <span class="title-ai">AI</span>
    </div>
    <ul class="sections">
      <li class="compose-emails" (click)="goToPlayground(playgroundType.Compose)">
        <div class="icons">
          <img src="/assets/images/icon-compose.svg" alt="compose emails" />
        </div>
        <div class="section-title">Compose emails</div>
        <div class="description">
          Create a draft or paste an email you'd like to reply to, and receive a ready-to-use proposal.
        </div>
      </li>
      <li class="compare-documents" (click)="scenarioChange(playgroundType.Compare)">
        <div class="icons">
          <img src="/assets/images/icon-compare.svg" alt="compare PDF documents" />
        </div>
        <div class="section-title">Compare PDF documents</div>
        <div class="description">Upload two PDFs and ask any question about their content and differences.</div>
      </li>
      <li class="summarise-document" (click)="scenarioChange(playgroundType.Summarise)">
        <div class="icons">
          <img src="/assets/images/icon-summarize.svg" alt="summarise document" />
        </div>
        <div class="section-title">Summarize document</div>
        <div class="description">Upload a PDF document for a quick and concise summary within seconds.</div>
      </li>
    </ul>
    <div class="free-play" (click)="goToPlayground(playgroundType.FreePlay)">
      <span class="icon-free-play"></span>
      <p class="free-play-content">
        <span class="free-play-title">Free play with prompts</span>
        <span class="free-play-description">Have fun generating content with your own creative prompts.</span>
      </p>
    </div>
  </div>
  <div class="home-footer">
    <div class="feedback-container">
      <span class="icon-email"></span>
      <a class="label send-feedback" [href]="feedbackMail">Send feedback</a>
    </div>

    <ul class="middle-container">
      <li>
        <span class="icon-security"></span>
        <span class="label">100 % Security</span>
      </li>
      <li><span class="icon-dot"></span></li>
      <li>
        <span class="icon-guideline"></span>
        <a
          class="label security-guidelines"
          href="https://henkelgroup.sharepoint.com/sites/SharedResponsibility/SitePages/Bewusstes-Umgehen-mit-ChatGPT.aspx"
          target="_blank"
        >
          Security guidelines</a
        >
      </li>
      <li><span class="icon-dot"></span></li>
      <li>
        <span class="icon-data-safety"></span>
        <span class="label">Data Safety</span>
      </li>
    </ul>
    <div class="made-with"><span class="icon-crafted"></span>Crafted by Henkel dx</div>
  </div>
</div>
