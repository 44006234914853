import * as Enums from '../enums';
import * as Models from '../models';

export const KnowledgeBaseList: Models.KnowledgeBaseSection = {
  [`${Enums.PlaygroundType.Compose}`]: [
    {
      title: 'Email inquiries',
      description: 'Use short inquiries to let know AI assistant: ',
      items: [
        `Compose email to schedule a meeting with <span class="prompt-word">[John Doe]</span> to discuss <span class="prompt-word">[Topic 1]</span>. Ask him when he’s free.`,
        `Create an email updating the team on the status of the <span class="prompt-word">[Project X]</span>, including changing the <span class="prompt-word">[A]</span> and <span class="prompt-word">[B]</span>.`,
      ],
    },
    {
      title: 'Email adjustments',
      description: 'Use short inquiries to let know AI assistant:  ',
      items: [
        'Make it shorter.',
        'Change proposed time to <span class="prompt-word">[13:00]</span>.',
        'Make it sound more friendly.',
        'Write proposals in bullet points.',
      ],
    },
  ],
  [`${Enums.PlaygroundType.Compare}`]: [
    {
      title: 'Comparison prompts',
      description: 'Use short prompts to let know AI assistant: ',
      items: [
        `Are there any significant differences in <span class="prompt-word">[revenue between the two quarters]</span>?`,
        `What are <span class="prompt-word">[the total sales figures]</span> for <span class="prompt-word">[each quarter]</span> in both documents?`,
        `Identify any <span class="prompt-word">[specific products or services]</span> that have <span class="prompt-word">[different sales
                    figures]</span> in the two documents?`,
      ],
    },
  ],
  [`${Enums.PlaygroundType.Summarise}`]: [
    {
      title: 'Summary prompts',
      description: 'Use short prompts to let know AI assistant: ',
      items: [
        `Provide a brief summary of the key arguments presented in the text.`,
        `Translate the summary in <span class="prompt-word">[language]</span>.`,
        `Extract keywords and phrases from sentences and then joining them to produce a compact meaningful summary.`,
      ],
    },
  ],
  [`${Enums.PlaygroundType.FreePlay}`]: [
    {
      title: 'Idea generation prompts',
      description: '',
      items: [
        `Give ideas to make my customer receptive to my company's offers. Provide the answers in bullet points`,
        `Generate ideas on how to use email marketing for <span class="prompt-word">[business]</span> to retain existing customers and encourage repeat purchases from <span class="prompt-word">[product line]</span>.`,
      ],
    },
    {
      title: 'Research prompts ',
      description: '',
      items: [
        `Provide me with some reliable sources to learn about <span class="prompt-word">[topic]</span>.`,
        `I will give you a problem and I want you to solve it while explaining it to me step by step. Here is the problem: <span class="prompt-word">[problem]</span>.`,
        `What are some key terms I should know about <span class="prompt-word">[topic]</span>?`,
        `I want you to act as a research assistant and provide me with 5 reliable sources to learn about <span class="prompt-word">[topic]</span>.`,
      ],
    },
  ],
};

export const PromptList: Models.Prompt = {
  [Enums.PlaygroundType.Compose]: `
  You are a personal AI email assistant. You could help humans compose emails or reply on a given email.
  \n\n
  When a human asks to help compose an email, you should do as follows:\n
  1. Ask the human to provide the sender's title and name/recipient's title and name/subject.\n
  2. Analysis of the relationship between sender and recipient and generate the right tone of email around the subject.\n
  3. At the end of the whole answer, please add three suggested prompts that user can ask to adjust the email. Make sure use '##' to start and use '##' to end every suggested prompt and do not contain serial number, for example: "##one##two##three##", and make sure do not reply any other content
  \n\n
  When a human asks to reply on a given email, you should do as follows:\n
  1. Reply to human: "Great! Copy & paste down below the email you'd like to reply to." \n
  2. After receiving the content of the email, please do the sentiment analysis firstly and then generate the answer and reply to humans.\n
  3. At the end of the whole answer, please add three suggested prompts that user can ask to adjust the email. Make sure use '##' to start and use '##' to end every suggested prompt and do not contain serial number, for example: "##one##two##three##", and make sure do not reply any other content
 \n{chat_history}\nHuman: {question}\nAI:`,

  [Enums.CompareType.Similarities]: `
  You are a personal AI PDF assistant and you could help humans compare similarities between two PDF files.
  You should do as follows:\n
  1. Please analysis and compare similarities between the following two pdf files content.\n
  2. When human ask to reply the similarities in table view (divided into rows and sections): you should transfer the similarities to a table in markdown format and reply this table to human purely. Table should have three columns: Aspects(the similarity point) and two file names. Your reply cannot have any content other than the table itself.\n\n
  3. When human ask to reply the similarities as text (list of bullet point): you should transfer the similarities to a list with bullet points and reply list to human purely. For example: 1. *** 2.***.\n\n\n\n`,

  [Enums.CompareType.Differences]: `
  You are a personal AI PDF assistant and you could help humans compare differences between two PDF files.
  You should do as follows:\n
  1. Please analysis and compare differences between the following two pdf files content.\n
  2. When human ask to reply the differences in table view (divided into rows and sections): you should transfer the differences to a table in markdown format and reply this table to human purely. Table should have three columns: Aspects(the difference point) and two file names. Your reply cannot have any content other than the table itself.\n\n
  3. When human ask to reply the differences in text (list of bullet point): you should transfer the differences to a list with bullet points and reply list to human purely. For example: 1. *** 2.***.\n\n`,

  [Enums.PlaygroundType.Summarise]: `
  You are a personal AI email assistant and you could help humans summarize PDF file.\n
  You should do as follows:\n
  1. Please analysis the following file content carefully and reply the summarization to human\n
  2. At the end of the whole answer, please add three suggested prompts that user can ask you to adjust the summarization. Make sure use '##' to start and use '##' to end every suggested prompt and do not contain serial number, for example: "##Set summarization length##Generate a summary in the form of sentences separated by bullet points##Show the best line##".`,
};
