import { Component, OnDestroy, OnInit, DestroyRef, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SharedModule } from './modules/shared.module';
import { IconRegistryService } from './services/icon-registry/icon-registry.service';
import { LoadingService } from './services/loading/loading.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EventMessage, EventType } from '@azure/msal-browser';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import * as Enums from './enums';

@Component({
  standalone: true,
  imports: [RouterModule, SharedModule],
  selector: 'playground-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  title = 'playground';
  isLoading$: Observable<boolean>;
  isLoggedIn = false;

  constructor(
    private loadingService: LoadingService,
    private iconRegistryService: IconRegistryService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public dialog: MatDialog,
  ) {
    this.isLoading$ = this.loadingService.isLoading$;
    this.iconRegistryService.addIcon();
    if (localStorage.getItem(Enums.DisclaimerKey.Disclaimer) !== Enums.DisclaimerValue.Visited) {
      this.dialog.open(DisclaimerComponent, {
        panelClass: 'disclaimer-panel',
        backdropClass: 'disclaimer-backdrop',
        disableClose: true,
      });
    }
  }

  ngOnInit(): void {
    try {
      this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.ACCOUNT_ADDED ||
              msg.eventType === EventType.ACCOUNT_REMOVED ||
              msg.eventType === EventType.LOGIN_SUCCESS,
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((result: EventMessage) => {
          if (result.eventType === EventType.LOGIN_SUCCESS) {
            const payload = result.payload as AuthenticationResult;
            this.authService.instance.setActiveAccount(payload.account);
          }

          if (this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/';
          }
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.checkAndSetActiveAccount();
        });
    } catch (error) {
      console.error('MSAL initialization error:', error);
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
}
