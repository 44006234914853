import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as Enums from '../../enums';
import * as Models from '../../models';
import { SharedModule } from '../../modules/shared.module';
import { UploadComponent } from '../upload/upload.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'playground-summary',
  standalone: true,
  imports: [UploadComponent, SharedModule],
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  static readonly maxToken = +environment.maxFileTokenSize;
  static readonly maxTokenError = `${Enums.NoticeText.SizeError} ${SummaryComponent.maxToken} tokens.`;
  uploadConfig: Models.UploadConfig = {
    uploadTittle: '',
    maxSizeInBytes: 1024 * 1024 * 1024,
    notice: `The upload limit is based on the amount of text in the PDF, rather than the file size`,
    maxToken: SummaryComponent.maxToken,
  };
  fileStatus = Enums.FileStatus;
  uploaded = Enums.FileStatus.Empty;
  noticeText = '';
  PDF!: Models.PDF;

  constructor(public dialogRef: MatDialogRef<SummaryComponent>) {}

  fileChanged(file: File) {
    if (!file) {
      this.uploaded = Enums.FileStatus.Empty;
      return;
    }
    if (this.PDF.token > SummaryComponent.maxToken) {
      const tokenPercentage = this.getTokenPercentage(this.PDF.token);
      this.uploaded = Enums.FileStatus.Error;
      this.noticeText = `Your file have ${tokenPercentage}% more text content than the maximum allowed limit`;
    } else {
      this.uploaded = file ? Enums.FileStatus.Success : Enums.FileStatus.Empty;
      this.noticeText = file ? Enums.NoticeText.Success : '';
    }
  }

  closeDialog(status: boolean) {
    const PDFs: Models.PDF[] = [this.PDF];
    localStorage.setItem('PDFs', JSON.stringify(PDFs));
    this.dialogRef.close(status);
  }

  handleFileError(error: Enums.FileErrorType) {
    this.uploaded = Enums.FileStatus.Error;
    if (error === Enums.FileErrorType.SizeError) {
      this.noticeText = SummaryComponent.maxTokenError;
    }
  }

  onPDFChange(PDF: Models.PDF) {
    this.PDF = PDF;
  }

  getTokenPercentage(fileTokenSize: number) {
    const maxTokenLimit = SummaryComponent.maxToken;
    const tokenPercentage = ((fileTokenSize - maxTokenLimit) / maxTokenLimit) * 100;
    return Math.round(tokenPercentage);
  }
}
