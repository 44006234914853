import { Route } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ChatComponent } from './containers/chat/chat.component';
import { HomeComponent } from './containers/home/home.component';
import { UnauthorizedComponent } from './containers/unauthorized/unauthorized.component';
import * as Enums from './enums';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: Enums.RoutePath.Home,
    pathMatch: 'full',
  },
  {
    path: Enums.RoutePath.Home,
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: Enums.RoutePath.Playground,
    component: ChatComponent,
    canActivate: [MsalGuard],
  },
  // TODO: UnauthorizedComponent and route may be not needed as MSAL will handle this
  {
    path: Enums.RoutePath.Unauthorized,
    component: UnauthorizedComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: '**',
    redirectTo: Enums.RoutePath.Home,
    pathMatch: 'full',
  },
];
