import { Injectable } from '@angular/core';
import * as marked from 'marked';

@Injectable({
  providedIn: 'root',
})
export class MarkedService {
  static readonly tableRegex = /^\s*\|(.+\|)+\s*\n\s*\|(.+\|)+\s*\n(\s*\|?[-:]+(\|[-:]+)+\s*\n)?(\s*\|(.+\|)+\s*\n)*/gm;

  formatUserAnswer(message: string) {
    return message.replace(/\n/g, '<br>');
  }

  formatTable(message: string) {
    const codeBlocks = message.match(/```(\w+)?\n([\s\S]*?)```/g);
    const tables = message.match(MarkedService.tableRegex);

    if (codeBlocks) {
      codeBlocks.forEach((code) => {
        const formattedCode = this.formatCodeBlock(code);
        message = message.replace(code, formattedCode);
      });
    }

    if (tables && tables.length > 0) {
      const sanitizedMessage = message.replace(/```(?:markdown)?\n|```$/g, '');
      return this.transferMDToTableHTML(sanitizedMessage);
    }

    return marked.marked(message);
  }

  formatCodeBlock(code: string): string {
    const match = code.match(/```(\w+)?\n([\s\S]*?)```/);
    if (!match) return '';

    const lang = match[1] || 'plaintext';
    const codeContent = match[2];

    const escapedCode = this.escapeHtml(codeContent.trim());

    return `<pre class="code-block"><code class="language-${lang}">${escapedCode}</code></pre>`;
  }

  escapeHtml(unsafe: string) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  transferMDToTableHTML(table: string): string {
    const renderer = new marked.Renderer();
    renderer.table = (header, body) => {
      const copyTableHTML = `
        <table class="copy-table hidden">
          <thead>${header}</thead>
          <tbody>${body}</tbody>
        </table>`;

      return `
        <div class="custom-table-container">
          <table class="custom-table">
            <thead>${header}</thead>
            <tbody>${body}</tbody>
          </table>
          <div class="table-action">
            <div class="table-action-item export table-action-export">
              <span class="icon-csv-normal table-action-export">${copyTableHTML}</span>
              <span class="action-name table-action-export">Export as .csv${copyTableHTML}</span>
            </div>
            <div class="table-action-item copy table-action-copy">
              <span class="icon-copy-normal table-action-copy">${copyTableHTML}</span>
              <span class="action-name table-action-copy">Copy${copyTableHTML}</span>
            </div>
          </div>
        </div>`;
    };
    return marked.marked(table, { renderer });
  }

  exportAsCSV(table: HTMLTableElement): void {
    const rows = table.querySelectorAll('tr');

    const data: string[][] = [];

    rows.forEach((row) => {
      const cells = row.querySelectorAll('td,th');
      const rowData: string[] = [];
      cells.forEach((cell) => {
        rowData.push(`"${cell.textContent?.trim()}"` ?? '');
      });
      data.push(rowData);
    });

    const csvRows: string[] = [];
    data.forEach((row) => {
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = 'Comparison_Result.csv';
    link.href = url;
    link.click();
    // document.removeChild(link);
  }

  transferTableToText(table: HTMLTableElement): string {
    const rows = table.rows;
    let result = '';
    // Loop through table rows and cells to build the text representation
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cells = row.cells;

      for (let j = 0; j < cells.length; j++) {
        const cell = cells[j];
        result += cell.textContent;

        if (j < cells.length - 1) {
          result += '\t'; // Use tab as a delimiter
        } else if (i < rows.length - 1) {
          result += '\n'; // Start a new line for the next row
        }
      }
    }
    return result;
  }
}
