<div class="home-page">
  <div class="home-header">
    <playground-header></playground-header>
  </div>
  <div class="home-content">
    <div class="unauthorized">
      <p class="title">Unauthorized</p>
      <p>You don't have permission to access the application.</p>
      <p>Please contact your administrator or <span class="logout" (click)="logout()">log out</span></p>
    </div>
  </div>
</div>
