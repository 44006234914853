import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ByteSizePipe } from '../pipes/byte.pipe';

const materialModules = [
  MatIconModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatTableModule,
  MatRadioModule,
  MatButtonModule,
  MatSelectModule,
  ReactiveFormsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatDialogModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatSidenavModule,
  ClipboardModule,
  MatProgressBarModule,
];

@NgModule({
  imports: [CommonModule, ...materialModules],
  declarations: [ByteSizePipe],
  exports: [...materialModules, CommonModule, FormsModule, HttpClientModule, ByteSizePipe],
})
export class SharedModule {}
